@import "../../style/mixins/font.scss";

.button {
    width: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 14px 32px;

    border: none;
    border-radius: 8px;

    transition: all linear 0.1s;

    @include Secondary;
    font-weight: 600;

    text-decoration: none;

    &.size-S {
        max-height: 34px;
    }

    &.size-L {
        max-height: 52px;
    }

    &.type-primary {
        background: var(--theme-button-primary-default);
        color: var(--theme-text-primary-inverted);
        box-shadow: 0px 0px 32px 4px rgba(73, 218, 192, 0.4);

        &:hover:not(:disabled) {
            background: var(--theme-button-primary-hover);
            box-shadow: none;
        }

        &:disabled {
            background-color: var(--theme-button-primary-disabled);
        }
    }

    &.type-secondary {
        background: var(--theme-button-secondary-default);
        color: var(--theme-text-primary);
        &:hover:not(:disabled) {
            background: var(--theme-button-secondary-hover);
        }
    }

    & img {
        margin-right: -16px;
        margin-left: -5px;
    }

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
    }
}
