@import "../../../style/mixins/font.scss";

.container {
  height: 148px;
  width: 328px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    width: 100% !important;
    padding: 14px 16px;
  }

  @media screen and (max-width: 768px) {
    display: flex !important;
  }

  .header {
    & p {
      @include Secondary;
      font-weight: 600;

      @media screen and (max-width: 480px) {
        font-weight: 400;
      }
    }

    & svg path {
      fill: var(--theme-text-primary);
    }

    svg:hover,
    svg:focus {
      path {
        fill: var(--theme-graphics-accent);
      }
    }

    .points {
      display: flex;
      align-items: center;
      gap: 10px;

      margin-top: 8px;

      & p {
        color: var(--theme-graphics-accent);
        @include Headline("h1");
      }

      & svg {
        width: 20px;
        height: 20px;

        path {
          fill: var(--theme-graphics-accent);
        }
      }
    }
  }

  button {
    width: 100%;
  }
}
