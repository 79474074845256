@import '../../style/mixins/font.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px);
  padding: var(--indent-xl) 0;

  @media (max-width: 480px) {
    min-height: calc(100vh - 140px);
  }

  .card {
    max-width: 400px;
    width: 100%;
    height: auto;
  }

  .form,
  .successWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--indent-xl);
  }

  .value {
    width: 100%;
  }

  .btn {
    align-self: center;
  }

  .error {
    width: 100%;

    @include Content;
    font-weight: 600;
    color: var(--theme_text-negative);
  }
}
