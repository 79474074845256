:root {
  /* Background */
  --theme-background-primary: #18171b;
  --theme-background-primary-inverted: #d7d7dd;
  --theme-background-secondary: #1e1d22;
  --theme-background-tertiary: #3a3b3f;
  --theme-background-table-hover: #35333c;

  /* Text */
  --theme-text-primary: #d7d7dd;
  --theme-text-primary-inverted: #18171b;
  --theme-text-secondary: #605f64;
  --theme_text-tertiary: #39373d;
  --theme_text-accent: #42ddc7;
  --theme_text-negative: #e25f14;
  --theme_text-positive: #2fd640;

  /* Border */
  --theme-border-default: #2a2b2e;
  --theme-border-hover: #42ddc7;
  --theme-border-focus: #42ddc7;
  --theme-border-invalid: #e25f14;
  --theme-border-button: linear-gradient(180deg, #9affff 0%, #01efdc 100%);

  /* Buttons */
  --theme-button-primary-default: #01efdc;
  --theme-button-primary-hover: #01efdc;
  --theme-button-primary-disabled: #152e3e;
  --theme-button-secondary-default: #28272d;
  --theme-button-secondary-hover: #333139;

  /* Tabs */
  --theme-tabs-active: #000000;
  --theme-tabs-hover: #1e1d22;

  /* Graphics */
  --theme-graphics-primary: #d7d7dd;
  --theme-graphics-primary-inverted: #1e1d22;
  --theme-graphics-secondary: #605f64;
  --theme-graphics-tertiary: #3a3b3f;
  --theme-graphics-accent: #42ddc7;
  --theme-graphics-accent-secondary: #ffc700;
  --theme-graphics-positive: #2fd640;
  --theme-graphics-negative: #e25f14;

  /* Box Shadow Glow */
  --theme-blue-glow: 0px 0px 17px 4px rgba(138, 251, 230, 0.83);
  --theme-gold-glow: 0px 0px 17px 4px rgba(255, 227, 130, 0.83);

  &.light {
    /* Background */
    --theme-background-primary: #18171b;
    --theme-background-primary-inverted: #d7d7dd;
    --theme-background-secondary: #1e1d22;
    --theme-background-tertiary: #3a3b3f;
    --theme-background-table-hover: #35333c;

    /* Text */
    --theme-text-primary: #d7d7dd;
    --theme-text-primary-inverted: #18171b;
    --theme-text-secondary: #605f64;
    --theme_text-tertiary: #39373d;
    --theme_text-accent: #42ddc7;
    --theme_text-negative: #e25f14;
    --theme_text-positive: #2fd640;

    /* Border */
    --theme-border-default: #2a2b2e;
    --theme-border-hover: #42ddc7;
    --theme-border-focus: #42ddc7;
    --theme-border-invalid: #e25f14;
    --theme-border-button: linear-gradient(180deg, #9affff 0%, #01efdc 100%);

    /* Buttons */
    --theme-button-primary-default: #01efdc;
    --theme-button-primary-hover: #01efdc;
    --theme-button-primary-disabled: #152e3e;
    --theme-button-secondary-default: #01efdc;
    --theme-button-secondary-hover: #152e3e;

    /* Tabs */
    --theme-tabs-active: #000000;
    --theme-tabs-hover: #1e1d22;

    /* Graphics */
    --theme-graphics-primary: #d7d7dd;
    --theme-graphics-primary-inverted: #1e1d22;
    --theme-graphics-secondary: #605f64;
    --theme-graphics-tertiary: #3a3b3f;
    --theme-graphics-accent: #42ddc7;
    --theme-graphics-accent-shadow: rgba(138, 251, 230, 0.83);
    --theme-graphics-accent-secondary: #ffc700;
    --theme-graphics-accent-secondary: rgba(255, 227, 130, 0.83);

    --theme-graphics-positive: #2fd640;
    --theme-graphics-negative: #e25f14;

    /* Box Shadow Glow */
    --theme-blue-glow: 0px 0px 17px 4px rgba(138, 251, 230, 0.83);
    --theme-gold-glow: 0px 0px 17px 4px rgba(255, 227, 130, 0.83);
  }
}
