@import "../../style/mixins/font.scss";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 16px;
  height: 60px;
  z-index: 999;
  background-color: var(--theme-background-primary);

  .header {
    height: 100%;
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .logo-wrapper {
      cursor: pointer;
    }

    .content {
      height: inherit;
      display: flex;
      height: 32px;
      flex-wrap: nowrap;

      align-items: center;
      gap: 32px;

      & .link-wrapper {
        display: none;

        @media screen and (min-width: 480px) {
          display: block;
        }
      }

      .profile {
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;

        @include Secondary;

        span {
          display: none;

          @media screen and (min-width: 480px) {
            display: inline;
          }
        }

        img {
          border-radius: 50%;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

.link-wrapper {
  display: block;

  a,
  span {
    all: unset;
    @include Secondary;
  }

  a {
    cursor: pointer;

    svg {
      display: block;
      margin: 0 auto;

      path {
        fill: white;
      }
    }

    &:hover,
    &.active {
      color: var(--theme-text-secondary);

      svg path {
        fill: var(--theme-text-secondary);
      }
    }

    &.active {
      cursor: default;
    }
  }
}
