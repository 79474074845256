@import '../../style/mixins/font.scss';

.container {
  min-height: calc(100vh - 60px);

  &__centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 480px) {
    min-height: calc(100vh - 140px);
  }

  .icon {
    max-width: 333px;
    width: 100%;
    height: auto;
    margin-bottom: var(--indent-m);

    @media (max-width: 780px) {
      max-width: 180px;
    }
  }

  .text {
    @include ParagraphBold;
    color: var(--theme_text-tertiary);
    text-align: center;

    @media (max-width: 780px) {
      max-width: 180px;
    }
  }

  .topBar {
    margin-top: var(--indent-l);
    display: flex;
    align-items: flex-end;
    gap: var(--indent-xl);

    .input {
      max-width: 500px;
      width: 100%;
    }

    .cartButton {
      margin-left: auto;
      padding: 0;
      width: 74px;
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 50%;

      .rect {
        position: relative;
        width: 29px;
        height: 29px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;

        .badge {
          position: absolute;
          top: 100%;
          left: 100%;
          transform: translate3d(-50%, -50%, 0);
          width: 17px;
          height: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--theme-text-primary-inverted);
          font-size: 12px;
          font-weight: 700;
          background-color: var(--theme-graphics-accent);
          border-radius: 50%;
        }
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--indent-xl);
    margin-top: var(--indent-xl);
    margin-bottom: var(--indent-xl);

    .card {
      width: 100%;
      height: 100%;
    }
  }
}
