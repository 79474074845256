@import '../../style/mixins/font.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  height: 100%;

  padding: 48px 40px 40px 40px;

  @media screen and (max-width: 480px) {
    padding: 48px 16px 40px 16px;
  }

  & .content-wrapper {
    @media screen and (max-width: 480px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    & .content {
      & .description {
        @include Paragraph('secondary');

        color: var(--theme-text-secondary);
        text-align: center;
      }
    }
  }

  button {
    width: 100%;
  }
}

.icon {
  display: block;
  margin: auto;

  width: 42px;
  height: 37px;

  path {
    fill: var(--theme-graphics-negative);
  }
}

.title {
  @include Headline('h2');

  margin-top: 18px;
  margin-bottom: 12px;

  text-align: center;
  color: var(--theme-text-primary);
}
