@import "../../style/mixins/font.scss";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;

    height: 100%;

    padding: 40px 40px 60px 40px;

    @media screen and (max-width: 480px) {
        padding: 48px 16px 40px 16px;
    }
}
