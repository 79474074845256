@import "../../../style/mixins/font.scss";

.nav {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 60px;
  background-color: var(--theme-background-secondary);

  @media screen and (min-width: 480px) {
    display: none;
  }

  .container {
    display: flex;
    flex-wrap: nowrap;
    gap: 32px;
    align-items: center;

    height: inherit;
    width: fit-content;
    margin: 0 auto;
  }
}
