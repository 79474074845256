.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.icon {
  max-width: 200px;
  width: 20vw;
  height: auto;
  animation: rotation 2s infinite linear;
  transform-origin: center 53%;

  path {
    fill: var(--theme-graphics-accent);
  }
}

.text {
  color: var(--theme-graphics-accent);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
