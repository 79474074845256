@import "./mixins/font.scss";

.tabs {
  // @include Paragraph("secondary");
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #605f64;

  .tabs-nav-operations {
    display: none;
  }

  .tabs-nav {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      gap: 14px;
    }

    .tabs-extra-content {
      color: var(--theme-graphics-primary);
      @include Headline("h2");
    }
  }

  .tabs-nav-list {
    display: flex;
    gap: 8px;

    .tabs-ink-bar {
      display: none;
    }
  }

  .tabs-nav-operations-hidden {
    display: none;
  }

  .tabs-tab {
    height: 25px;
    padding: 3px 12px 3px 12px;

    display: flex;
    align-items: center;

    border: 1px solid #2a2b2e;
    border-radius: 29px;

    &:hover {
      cursor: pointer;
      background-color: #1e1d22;
    }

    &-active {
      color: white;
      background-color: black;
    }
  }

  &[data-size="S"] {
    .tabs-tab {
      padding: 8px 16px 8px 16px;
      height: 34px;
    }
  }
}
