@import '../../style/mixins/font.scss';

.container {
  margin-top: 40px;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 996px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;
    }

    .title {
      .greeting-title {
        display: flex;
        align-items: flex-start;
        gap: 15px;

        h1 {
          @include Headline;
        }
      }

      p {
        margin-top: 16px;
      }
    }

    .cards {
      display: flex;
      gap: 12px;

      @media screen and (max-width: 996px) {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        .karma-first-card {
          flex: 1;
        }

        .karma-second-card {
          flex: 2;
        }
      }
    }
  }

  .table {
    margin-top: 50px;
  }

  .couponLink {
    @include Content;

    font-weight: 300;
    color: var(--theme_text-accent);

    text-decoration: none;
  }
}
