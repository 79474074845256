@import '../../style/mixins/font.scss';

.container {
  // overflow-x: auto;

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 60px;

    color: #d7d7dd;

    thead {
      td:not(:first-child) {
        padding: 0 35px 0 0;
        color: var(--theme-graphics-tertiary);
        @include Secondary;

        span {
          position: relative;
          cursor: pointer;
          user-select: none;
          white-space: nowrap;

          &.activeSort {
            color: var(--theme-graphics-secondary);

            &::before,
            &::after {
              color: var(--theme-graphics-tertiary);
            }

            &.activeSortAsc {
              &::before {
                color: var(--theme-graphics-secondary);
              }
            }

            &:not(.activeSortAsc) {
              &::after {
                color: var(--theme-graphics-secondary);
              }
            }
          }

          &::before,
          &::after {
            content: '';

            position: absolute;
            color: var(--theme-graphics-tertiary);

            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-left-width: 4px;
            border-left-style: solid;
          }

          &::before {
            top: 4px;
            right: -10px;
            transform: rotate(270deg);
          }

          &::after {
            top: 10px;
            right: -10px;
            transform: rotate(90deg);
          }
        }
      }
    }

    tbody {
      tr:first-child {
        height: 20px;
      }

      .no-data {
        height: 320px;

        @media screen and (max-width: 768px) {
          height: 200px;
        }

        td div {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #39373d;
          text-align: center;
          font-weight: 700;

          p {
            @media screen and (max-width: 768px) {
              width: 280px;
            }
          }
        }

        &_icon {
          margin-bottom: var(--indent-xl-3);
          height: auto;

          @media (max-width: 480px) {
            max-width: 100px;
            margin-bottom: var(--indent-m);
          }
        }

        &_text {
          @include ParagraphBold;
          color: var(--theme_text-tertiary);
          text-align: center;

          @media (max-width: 768px) {
            max-width: 190px;
          }
        }
      }

      tr:nth-child(even):not(.no-data) {
        td {
          background-color: #1e1d22;
        }
      }

      tr:nth-child(-n + 4) {
        .cell-points {
          color: inherit;
          text-decoration: none;
          .now-points {
            color: var(--theme-graphics-accent);
          }

          .now-points-icon {
            path {
              fill: var(--theme-graphics-accent);
            }
          }
        }
      }

      tr {
        height: 66px;

        transition: all 0.2s linear;

        &:hover:not(.no-data):not(.spinnerRow) {
          td {
            background-color: #35333c;
          }
        }

        .cell-user-name {
          display: flex;
          align-items: center;
          gap: 16px;
          color: inherit;
          text-decoration: none;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .number {
            width: 32px;

            @media screen and (max-width: 768px) {
              display: none;
            }
          }

          .avatar img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
        }

        .cell-points {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
          color: inherit;
          text-decoration: none;

          .now-points-icon {
            path {
              fill: #d7d7dd;
            }
          }

          svg {
            width: 22px;
            height: 22px;
          }
        }

        td {
          padding: 0 22px;
          vertical-align: middle;
        }

        td:first-child {
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;

          &:not(.no-data) {
            width: 100%;
          }
        }

        td:last-child {
          color: #605f64;
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
        }
      }
    }

    td:not(:first-child) {
      text-align: right;
    }

    @media screen and (max-width: 768px) {
      td:last-child:not(.no-data td) {
        display: none;
      }

      td:nth-child(2) {
        padding-left: 0;

        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    }
  }

  .input-cell {
    .input {
      @media screen and (min-width: 768px) {
        width: 400px;
      }
    }
  }

  .spinnerCell {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
}
