.wrapper {
  line-height: inherit;
}

.icon {
  position: relative;
  top: 3%;
  width: auto;
  animation: rotation 2s infinite linear;
  transform-origin: center 53%;

  path {
    fill: var(--theme-graphics-accent);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
