@import "../../style/mixins/font.scss";

.container {
    height: 100%;
    padding: 40px 16px 0 16px;

    h1 {
        margin-bottom: 24px;
    }

    .card {
        height: 100%;
        all: unset;

        form {
            padding-bottom: 80px;

            & div {
                flex-direction: column;
                gap: 8px;

                & div {
                    width: 100% !important;
                    flex-direction: row;
                    height: 48px;
                    border-radius: 12px;
                }
            }

            button {
                height: 48px;
                max-height: 48px;
                width: 100%;
            }

            div:last-child {
                top: 110px;
            }
        }
    }
}

.override {
    width: 100%;
    height: 50%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 99999;
    position: fixed;
    bottom: 0;
    left: 0;
}
