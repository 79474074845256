@import '../../style/mixins/font.scss';

.container {
  margin-top: 40px;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 996px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;
    }

    .title {
      .greeting-title {
        display: flex;
        align-items: flex-start;
        gap: 15px;

        h1 {
          @include Headline;
        }
      }

      p {
        margin-top: 16px;
      }
    }
  }

  .table {
    margin-top: 50px;
  }
}
