@import "../../style/mixins/font.scss";

.container {
    height: 100vh;

    .loginContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;

        @media (max-width: 780px) {
            flex-direction: column;
            justify-content: center;
            height: auto;
        }

        .content {
            max-width: 360px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 1;

            @media (max-width: 780px) {
                justify-content: center;
                align-items: center;
            }

            &_text {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                margin-bottom: var(--indent-xl-2);

                @media (max-width: 780px) {
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                @media (max-width: 480px) {
                    margin-bottom: var(--indent-xl-5);
                }
            }
        }

        .greeting {
            margin-bottom: var(--indent-m);
            &_info {
                @include Paragraph;
            }
        }
    }

    .button {
        @media (max-width: 480px) {
            width: 100%;
        }
    }

    .background {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: auto;

        @media (max-width: 780px) {
            position: relative;
            margin-bottom: var(--indent-m);
            max-width: 300px;
            width: 100%;
            height: auto;
        }
    }
}
