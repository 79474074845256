@mixin baseText($font-size: 14px, $line-height: 17px, $letter-spacing: unset) {
  font-style: normal;
  font-weight: normal;
  font-size: $font-size;
  line-height: $line-height;
  color: var(--theme-font-text-color);
}

@mixin baseTitle($font-size: 36px, $line-height: 44px, $letter-spacing: unset) {
  @include baseText;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@mixin baseParagraph(
  $font-size: 16px,
  $line-height: 32px,
  $letter-spacing: -0.02em
) {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: var(--theme-font-paragraph-color);
}

@mixin baseFontSize {
  font-size: (1/1440) * 100vw;

  @media screen and (max-width: 767px) {
    font-size: (1/375) * 100vw;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: (1/1023) * 100vw;
  }
}

@mixin Headline($headlineType: "h1") {
  font-family: "RF Dewi Bold";
  font-style: normal;
  font-weight: 700;

  @if ($headlineType == "h1") {
    font-size: 36px;
    line-height: 44px;
  } @else {
    font-size: 24px;
    line-height: 30px;
  }

  @media screen and (max-width: 768px) {
    @if ($headlineType == "h1") {
      font-size: 24px;
      line-height: 30px;
    } @else {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@mixin Paragraph($type: "primary") {
  font-family: "RF Dewi Regular";
  font-style: normal;
  font-weight: 400;

  @if ($type == "primary") {
    font-size: 16px;
    line-height: 24px;
  } @else {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    @if ($type == "primary") {
      font-size: 16px;
      line-height: 24px;
    } @else {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@mixin Secondary {
  font-family: "RF Dewi Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin Primary {
  font-family: "RF Dewi Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin ParagraphBold($type: "bold") {
  font-family: "RF Dewi Regular";
  font-style: normal;
  font-weight: 700;

  @if ($type == "bold") {
    font-size: 16px;
    line-height: 24px;
  } @else {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;

    @if ($type == "primary") {
      font-weight: 700;
    } @else {
      font-weight: 600;
    }
  }
}

@mixin Content {
  font-family: "RF Dewi Regular";
  font-style: normal;
  font-weight: 700;

  font-size: 12px;
  line-height: 16px;
}

@mixin Input() {
  font-family: "RF Dewi Regular";
  font-style: normal;
  font-weight: 400;
}
