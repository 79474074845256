@import "./mixins/.index";

@font-face {
  font-family: "RF Dewi Regular";
  src: url("../assets/fonts/RFDewi-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "RF Dewi Semibold";
  src: url("../assets/fonts/RFDewi-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "RF Dewi Bold";
  src: url("../assets/fonts/RFDewi-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

:root {
  font-family: "RF Dewi Regular";
  color: var(--theme-text-primary);
}

body {
  padding-top: var(--header-height);
}

.scroll-disabled-modal,
.scroll-disabled-menu {
  overflow: hidden;
  overscroll-behavior-y: none;

  body {
    overflow: hidden;
    overscroll-behavior-y: none;
  }
}

.-scroll-theme {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: bar(--theme-scrollbar-bg);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--theme-scrollbar-thumb);
    border-radius: 20px;
  }
}
