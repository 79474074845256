:root {
  /* Indent */
  --indent-xl-7: 60px;
  --indent-xl-6: 52px;
  --indent-xl-5: 40px;
  --indent-xl-4: 36px;
  --indent-xl-3: 32px;
  --indent-xl-2: 28px;
  --indent-xl: 24px;
  --indent-l: 20px;
  --indent-m: 16px;
  --indent-s: 12px;
  --indent-xs: 8px;
  --indent-xs-2: 4px;
}
