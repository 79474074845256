@import '../../../style/mixins/font.scss';

.container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}

.image {
  max-width: 100%;
  max-height: 250px;
  margin-bottom: var(--indent-l);
  object-fit: contain;
}

.title {
  @include Paragraph('primary');
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: var(--indent-m);
  align-items: flex-end;
  margin-top: auto;

  .button {
    padding-left: 14px;
    padding-right: 14px;
  }
}
