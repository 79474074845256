@import '../../style/mixins/font.scss';

.container {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    height: calc(100vh - 140px);
  }

  .icon {
    max-width: 333px;
    width: 100%;
    height: auto;
    margin-bottom: var(--indent-m);

    @media (max-width: 768px) {
      max-width: 180px;
    }
  }

  .text {
    @include ParagraphBold;
    color: var(--theme_text-tertiary);
    text-align: center;

    @media (max-width: 768px) {
      max-width: 180px;
    }
  }
}
