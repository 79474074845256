@import '../../style/mixins/font.scss';

.h1 {
    @include Headline('h1')
}

.h2 {
    @include Headline('h2')
}

.span {
    
}