@import "../../../style/mixins/font.scss";

.popup {
  display: inline-block;
  opacity: 0;
  width: 100%;

  position: absolute;

  max-width: 240px;
  padding: 16px;
  border-radius: 16px;

  color: #18171b;
  background-color: #d7d7dd;

  word-wrap: break-word;

  @include Content;
  font-weight: 600;

  transition: opacity 0.1s linear;

  &::before {
    content: "";

    position: absolute;
    color: #d7d7dd;

    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left-width: 8px;
    border-left-style: solid;
  }

  // TOP
  &.arrow-position-TopLeft::before {
    top: -11px;
    left: 20px;
    transform: rotate(270deg);
  }
  &.arrow-position-TopCenter::before {
    top: -11px;
    left: 50%;
    transform: translateX(-50%) rotate(270deg);
  }
  &.arrow-position-TopRight::before {
    top: -11px;
    right: 20px;
    transform: rotate(270deg);
  }

  // BOTTOM
  &.arrow-position-BottomLeft::before {
    bottom: -11px;
    left: 20px;
    transform: rotate(90deg);
  }
  &.arrow-position-BottomCenter::before {
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
  &.arrow-position-BottomRight::before {
    bottom: -11px;
    right: 20px;
    transform: rotate(90deg);
  }

  // LEFT
  &.arrow-position-LeftTop::before {
    top: 15px;
    left: -7px;
    transform: rotate(180deg);
  }
  &.arrow-position-LeftCenter::before {
    top: 50%;
    left: -7px;
    transform: translateY(-50%) rotate(180deg);
  }
  &.arrow-position-LeftBottom::before {
    bottom: 15px;
    left: -7px;
    transform: rotate(180deg);
  }

  // RIGHT
  &.arrow-position-RightTop::before {
    top: 15px;
    right: -7px;
  }
  &.arrow-position-RightCenter::before {
    top: 50%;
    right: -7px;
    transform: translateY(-50%);
  }
  &.arrow-position-RightBottom::before {
    bottom: 15px;
    right: -7px;
  }

  &.transition-in {
    display: inline-block;
    animation: fade-in 0.2s;
    animation-fill-mode: forwards;
  }

  &.transition-out {
    display: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
