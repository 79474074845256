.container {
  background-color: #18171b;

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }

  &.login {
    @media (max-width: 480px) {
      padding-bottom: 0px;
    }
  }

  .header-wrapper {
    height: 60px;
  }

  .layout {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 16px;

    .content-wrapper {
      max-width: 1110px;
      width: 100%;
    }
  }
}
