@import "../../style/mixins/font.scss";

.container {
    display: flex;
    align-items: center;
    gap: 10px;

    width: fit-content;

    box-sizing: border-box;

    height: 36px;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid var(--theme-border-default);

    @include Input;

    background-color: var(--theme-background-secondary);
    color: var(--theme-text-secondary);

    transition: all 0.1s linear;
    outline: none;
    box-shadow: none;

    &.size-M {
        height: 48px;
        padding: 14px 12px;
    }

    &:hover {
        border-color: var(--theme-border-hover);
    }

    &.focused {
        color: var(--theme-text-primary);
        border-color: var(--theme-border-hover);
    }

    &.invalid {
        border-color: var(--theme-border-invalid);
    }

    & > span {
        display: flex;
        align-items: center;
    }

    & svg:last-child {
        width: 24px;
        height: 24px;
    }
}

.input {
    all: unset;
    width: 100%;
    color: var(--theme-text-primary);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: var(--theme-text-primary);
        transition: background-color 5000s ease-in-out 0s;
    }
}

.icon {
    width: 16px;
    height: 16px;

    border: none;
    margin: 0;
    padding: 0;
    outline: none;
}
