.container {
    height: 126px;
    width: 191px;

    padding: 20px;
    border-radius: 16px;

    background-color: #1e1d22;

    @media screen and (max-width: 480px) {
        border-radius: 16px;
    }

    &:not(.type-form) {
        @media screen and (max-width: 480px) {
            width: 140px;
            padding: 14px 16px;
        }
    }

    &.type-long {
        width: 326px;

        @media screen and (max-width: 480px) {
            width: 180px;
        }
    }

    &.type-form {
        width: 346px;
    }
}
