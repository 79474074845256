@import "../../style/mixins/font.scss";

.container {
    & .header {
        display: flex;
        justify-content: space-between;

        & svg {
            cursor: pointer;
        }

        & img {
            border-radius: 50%;

            max-width: 100px;
            max-height: 100px;
            width: 100px;
            height: 100px;

            @media screen and (max-width: 480px) {
                max-width: 68px;
                max-height: 68px;
                width: 68px;
                height: 68px;
            }
        }
    }

    & .name {
        margin-top: 16px;
        @include Headline("h2");
    }

    & .scores {
        margin-top: 12px;
        @include Headline("h2");

        display: flex;
        align-items: center;
        gap: 10px;

        &.type-primary {
            color: var(--theme_text-positive);

            & svg path {
                fill: var(--theme_text-positive);
            }
        }

        &.type-secondary {
            color: var(--theme_text-negative);

            & svg path {
                fill: var(--theme_text-negative);
            }
        }
    }

    & .comment-title,
    & .date-title {
        @include Secondary;
        color: var(--theme-text-secondary);
    }

    & .comment-title {
        margin-top: 40px;
        margin-bottom: 12px;

        @media screen and (max-width: 480px) {
            margin-top: 24px;
        }
    }

    & .date-title {
        margin-top: 24px;
    }

    & .comments {
        @include Primary;
    }
}
