@import '../../style/mixins/font.scss';

.container {
  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 60px;

    color: #d7d7dd;

    tbody {
      tr:first-child {
        height: 20px;
      }

      .userName {
        max-width: 380px;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .no-data {
        td div {
          margin-top: 90px;

          display: flex;
          flex-direction: column;
          align-items: center;
          color: #39373d;
          font-weight: 700;

          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }

          & svg {
            width: 201px;
            height: 175px;

            @media screen and (max-width: 768px) {
              width: 91px;
              height: 79px;
            }
          }

          & p {
            text-align: center;

            @media screen and (max-width: 768px) {
              width: 150px;
            }
          }
        }
      }

      @media screen and (min-width: 768px) {
        tr:nth-child(even):not(.no-data) {
          td {
            background-color: #1e1d22;
          }
        }
      }

      tr:nth-child(-n + 4) {
        .cell-points {
          .now-points {
            color: var(--theme-graphics-accent);
          }

          .now-points-icon {
            path {
              fill: var(--theme-graphics-accent);
            }
          }
        }
      }

      tr {
        height: 66px;

        &:not(.no-data) {
          cursor: pointer;
        }

        &:hover:not(.no-data) {
          td {
            background-color: #35333c;
          }
        }

        @media screen and (max-width: 768px) {
          height: 108px;

          td {
            padding-top: 20px !important;
          }
        }

        transition: all 0.2s linear;

        .cell-user-name {
          display: flex;
          align-items: center;
          gap: 16px;
          color: inherit;
          max-width: 320px;
          text-decoration: none;

          .name {
            @include Primary;
            max-width: 320px;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: 768px) {
              width: 100px;
            }
          }

          .avatar {
            display: flex;
            align-items: center;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
            }
          }
        }

        .cell-points {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;

          @include Primary;

          color: #e25f14;

          path {
            fill: #e25f14;
          }

          &.positive {
            color: #2fd640;

            path {
              fill: #2fd640;
            }
          }

          svg {
            width: 22px;
            height: 22px;
          }
        }

        .cell-comment {
          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .cell-comment,
        .cell-date {
          text-align: left;
        }

        td {
          vertical-align: middle;
        }

        td:first-child {
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;
        }

        td:last-child {
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
        }

        td:last-child,
        td:nth-child(2) {
          color: #605f64;
        }
      }
    }

    td:not(:first-child) {
      text-align: right;
    }

    td {
      padding: 0 22px;
    }

    @media screen and (max-width: 768px) {
      tr {
        position: relative;

        td {
          padding-top: 20px !important;
        }
      }

      transition: all 0.2s linear;

      .cell-user-name {
        display: flex;
        align-items: center;
        gap: 16px;
        line-height: 24px;
        color: inherit;
        text-decoration: none;

        .name {
          @include Primary;

          @media screen and (max-width: 768px) {
            width: 100px;
          }

          .avatar {
            display: flex;
            align-items: center;

            img {
              display: block;
              width: 42px;
              height: 42px;
              border-radius: 50%;
            }

            td:nth-child(3) {
              border-top-right-radius: 24px;
              border-bottom-right-radius: 24px;
            }

            td:nth-child(4) {
              position: absolute;
              top: -25px;
              left: 0;
            }
          }

          .cell-comment {
            p {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .cell-comment,
          .cell-date {
            text-align: left;
          }

          td {
            vertical-align: middle;
          }

          td:first-child {
            border-top-left-radius: 24px;
            border-bottom-left-radius: 24px;
          }

          td:last-child {
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
          }

          @media screen and (max-width: 480px) {
            td:first-child {
              border-top-left-radius: 16px;
              border-bottom-left-radius: 16px;
            }

            td:last-child {
              border-top-right-radius: 16px;
              border-bottom-right-radius: 16px;
            }
          }

          td:last-child,
          td:nth-child(2) {
            color: #605f64;
          }
        }
      }

      .input-cell {
        .input {
          @media screen and (min-width: 768px) {
            width: 400px;
          }
        }
      }

      @media screen and (max-width: 480px) {
        td:nth-child(3) {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
    }

    .input-cell {
      .input {
        @media screen and (min-width: 768px) {
          width: 400px;
        }
      }
    }
  }
}
