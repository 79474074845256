@import '../../../style/mixins/font.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & p {
      @include Secondary;
      font-weight: 600;

      @media screen and (max-width: 480px) {
        font-weight: 400;
      }
    }

    & svg path {
      fill: var(--theme-text-primary);
    }

    svg:hover,
    svg:focus {
      path {
        fill: var(--theme-graphics-accent);
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .points {
      display: flex;
      align-items: center;
      gap: 10px;

      & p {
        color: var(--theme-graphics-accent);
        @include Headline('h2');
      }

      & svg {
        width: 20px;
        height: 20px;

        path {
          fill: var(--theme-graphics-accent);
        }
      }
    }

    .date {
      @include Content;
      font-weight: 600;
      color: var(--theme-text-secondary);
    }
  }
}

.error {
  @include Secondary;
  color: var(--theme_text-negative);
}
