@import '../../style/mixins/font.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  height: 100%;

  padding: 48px 40px 40px 40px;

  @media screen and (max-width: 480px) {
    padding: 48px 16px 40px 16px;
  }

  & .content-wrapper {
    @media screen and (max-width: 480px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    & .content {
      & .description {
        @include Paragraph('secondary');

        color: var(--theme-text-secondary);
        text-align: center;
      }
    }
  }

  button {
    width: 100%;
  }
}

.icon {
  display: block;

  width: 24px;
  height: 24px;

  path {
    fill: var(--theme-graphics-accent);
  }
}

.title {
  @include Headline('h2');

  margin-bottom: var(--indent-s);

  text-align: center;
  color: var(--theme-text-primary);
}

.image {
  display: block;
  margin: 0 auto var(--indent-s);
  max-width: 100%;
  width: 100px;
}

.productTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--indent-s);
  gap: var(--indent-l);
  line-height: 24px;
}

.productPrice {
  margin-bottom: var(--indent-l);
  display: flex;
  align-items: center;
  gap: var(--indent-s);
}
