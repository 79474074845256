@import '../../../style/mixins/font.scss';

.container {
  margin-top: 40px;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 28px;

    @media screen and (max-width: 996px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: stretch;
      gap: 20px;

      .back {
        display: flex;
        gap: 5px;
        padding: 0;

        @include Content;
        font-weight: 600;
        color: #605f64;
        background: none;
        border: none;
        cursor: pointer;
      }

      .profile {
        display: flex;
        align-items: center;
        gap: 18px;

        & img {
          width: 68px;
          height: 68px;
          border-radius: 50%;
        }

        .user-wrapper {
          align-self: stretch;
          gap: 10px;
          display: flex;
          flex-direction: column;
        }

        .user-info {
          display: flex;
          height: 35px;
          align-items: flex-start;
          gap: 15px;

          & h1 {
            align-self: flex-end;
            @include Headline('h2');

            @media screen and (max-width: 768px) {
              width: min-content;
            }
          }
        }
      }
    }

    .cards {
      display: flex;
      gap: 12px;

      @media screen and (max-width: 996px) {
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        .karma-mobile-card {
          display: none;
        }
      }

      @media screen and (max-width: 768px) {
        .karma-first-card {
          display: none;
        }

        .karma-second-card {
          display: none;
        }

        .karma-mobile-card {
          display: block;
        }
      }
    }
  }

  .table {
    margin-top: 50px;

    @media screen and (max-width: 768px) {
      margin-top: 28px;
    }
  }
}
