@import "../../../style/mixins/font.scss";

.container {
  .form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .inputs {
      display: flex;
      justify-content: space-between;

      .value {
        width: 98px;
      }

      .comment {
        width: 200px;
      }
    }
  }
}

.okane-icon-focused {
  path {
    fill: var(--theme-text-primary);
  }
}

.close-icon {
  z-index: 99999;
  opacity: 0.25;

  &.focused {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
}

.error {
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  width: 100%;

  @include Content;
  font-weight: 600;
  color: var(--theme_text-negative);
}
