@import '../../style/mixins/font.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  height: 100%;

  max-height: 70vh;

  padding: 48px 40px 40px 40px;

  overflow-y: scroll;

  @media screen and (max-width: 480px) {
    padding: 48px 16px 40px 16px;
  }

  & .content-wrapper {
    @media screen and (max-width: 480px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    & .content {
      & .description {
        @include Paragraph('secondary');

        color: var(--theme-text-secondary);
        text-align: center;
      }
    }
  }

  button {
    width: 100%;
  }
}

.icon {
  display: block;

  width: 24px;
  height: 24px;

  path {
    fill: var(--theme-graphics-accent);
  }
}

.title {
  @include Headline('h2');

  margin-bottom: var(--indent-s);

  text-align: center;
  color: var(--theme-text-primary);
}

.item {
  display: flex;
  gap: var(--indent-s);
  align-items: center;
  padding: var(--indent-l) 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--theme-background-tertiary);
  }
}

.image {
  display: block;
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.itemTitle {
  align-self: center;
}

.itemPrice {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--indent-s);
}
