.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  background: #18171bb4;

  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  & .modal-content {
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
}

.content {
  position: relative;
  box-sizing: border-box;
  min-height: 50px;
  min-width: 50px;

  opacity: 1;
  width: 440px;
  background-color: var(--theme-background-secondary);
  border-radius: 16px;

  @media screen and (max-width: 480px) {
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
}
