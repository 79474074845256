@import "../../style/mixins/font.scss";

.tag {
  position: relative;
  display: inline-block;

  color: var(--theme-text-primary-inverted);
  text-align: center;
  padding: 2px 6px 3px 6px;
  border-radius: 5px;

  @include Content;

  &::after,
  &::before {
    content: "";

    position: absolute;

    top: 50%;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left-width: 5px;
    border-left-style: solid;
    transform: translateY(-50%);
  }

  &::after {
    right: -4px;
  }

  &::before {
    left: -4px;
    transform: translateY(-50%) rotate(180deg);
  }

  &.primary {
    background-color: var(--theme-graphics-accent);
    box-shadow: var(--theme-blue-glow);

    &::after,
    &::before {
      border-left-color: var(--theme-graphics-accent);
    }
  }

  &.secondary {
    background-color: var(--theme-graphics-accent-secondary);
    box-shadow: var(--theme-gold-glow);

    &::after,
    &::before {
      border-left-color: var(--theme-graphics-accent-secondary);
    }
  }
}
